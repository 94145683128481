import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { setTabToState } from './tabIdentifierSlice';
import { ParcelDetail_Log_Event } from '../../../utils/constants/constants';
import logAPI from '../../../common/Api/logAPI';
import { formatZip4Field } from '../../../utils/common/commonUtils';
import SpectrTable from '../spectr-table/SpectrTable';

type Props = {
    propertyData: any;
    tabName: any;
    tabHeader: any;
    totalRowCount: number;
    loggingEventName: string;
    columns?: any[];
    rows?: any[];
};

interface Column {
    id: string;//'apn' | 'ownerName' | 'address' | 'city' | 'state' | 'zip' | 'landUse' | 'briefLegal';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

interface Data {
    apn: string,
    ownerName: string,
    address: string,
    city: string,
    state: string,
    formattedZip: string,
    zip: string,
    zip4: string,
    landUse: string,
    briefLegal: string,
    propertyId: string,
    fips: string,
    unit: string,
    unitType: string
}

const PropertyTable = ({ propertyData, tabName, tabHeader, totalRowCount, columns, rows, loggingEventName }: Props) => {
    // const tabState = useAppSelector(tabIdentifier);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    if (!columns) {
        columns = [
            { id: 'apn', label: 'APN', minWidth: 180 },
            { id: 'ownerName', label: 'OWNER NAME', minWidth: 290 },
            {
                id: 'address',
                label: 'ADDRESS',
                minWidth: 190,
            },
            {
                id: 'city',
                label: 'CITY',
                minWidth: 160,
            },
            {
                id: 'state',
                label: 'STATE',
                minWidth: 60,
            },
            {
                id: 'formattedZip',
                label: 'ZIP',
                minWidth: 135,
                format: (value: number) => value.toFixed(2),
            },
            {
                id: 'landUse',
                label: 'LAND USE',
                minWidth: 200,
            },
            {
                id: 'briefLegal',
                label: 'BRIEF LEGAL',
                minWidth: 170,
            }
        ];
    }

    if (!rows) {
        rows = [];
        propertyData?.forEach((element: any) => {
            rows?.push({
                apn: element.apn,
                ownerName: element.ownerName,
                address: element.address,
                city: element.city,
                state: element.state,
                formattedZip: formatZip4Field(element.zip, element.zip4),
                zip: element.zip,
                zip4: element.zip4,
                landUse: element.landUse,
                briefLegal: element.breifLegal,
                propertyId: element.propertyId,
                fips: element.fips,
                unit: element.unitNumber,
                unitType: element.unit_type,
                subdivision_name: element.legal_subdivision_name ?? "",
                longitude: element.longitude ?? "",
                latitude: element.latitude ?? ""
            });
        });

        // function createData(
        //     apn: string,
        //     ownerName: string,
        //     address: string,
        //     city: string,
        //     state: string,
        //     zip: string,
        //     landUse: string,
        //     briefLegal: string,
        //     propertyId: string,
        //     fips: string,
        //     unit: string,
        //     unitType: string
        // )
        //     : Data {
        //     return { apn, ownerName, address, city, state, zip, landUse, briefLegal, propertyId, fips, unit, unitType };
        // }

        // rows = [];
        // propertyData?.forEach((element: any) => {
        //     var data = createData(element.apn, element.ownerName, element.address, element.city,
        //         element.state, formatZip4Field(element.zip, element.zip4), element.landUse, element.breifLegal,
        //         element.propertyId, element.fips, element.unitNumber, element.unit_type);
        //     rows.push(data);
        // });
    }


    function onRowClicked(row: any) {
        if (!row)
            return;

        const encodedSubdivisionName = encodeURIComponent(row.subdivision_name);
        let query = "/parcel-detail/" + row.propertyId + "?fips=" + row.fips + "&subdivision_name=" + encodedSubdivisionName + "&longitude=" + row.longitude + "&latitude=" + row.latitude;
        navigate(query);
        dispatch(setTabToState({ tabName: tabName }));
        logAPI({
            Event: ParcelDetail_Log_Event,
            FullStreetAddress: row.address,
            city: row.city,
            state: row.state,
            zip: row.zip,
            zip4: row.zip4,
            UnitNumber: row.unit,
            APN: row.apn,
            Fips: row.fips,
            SearchOptionUsed: loggingEventName
        });
    }

    return (
        <SpectrTable tabName={tabName} onRowClicked={onRowClicked} columns={columns} rows={rows} totalRowCount={totalRowCount} />
    )
}

export default PropertyTable