import { Box, Grid, Typography } from "@mui/material"
import { ClearButton, GradientButton, GridItem, StyledAutocomplete, ThemedClearIcon } from "../../components/StyledComponents/CommonControls"
import { StyledTextField } from "../../components/StyledComponents/StyledTextField"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stateListArray } from "../../utils/constants/StateListConstant";
import { useEffect, useRef, useState } from "react";
import styles from './assessmentSearch.module.css';
import { AssesementSearchFormType } from "../../entities/Types/AssesementSearchFormType";
import { CountyListData, getCountyByStateCode } from "../apn-search/getCounty/getCountySlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingProgressModel from "../../components/ui/circularProgress/LoadingProgress";
import { ASSESSMENT_SEARCH_FORM, ASSESSMENT_SEARCH_TAB } from "../../utils/constants/tabConstants";
import { resetAssessmentSearchState } from "./AssessmentSearchSlice";
import { AssessmentSearchFormErrorMessages } from "../../utils/constants/constants";
import { specialCharacterFormatting, validateLotFromAndTo } from "../../utils/common/commonUtils";
import { stateParameter } from "../../entities/ApiModel/CountyRequest";

interface Props {
    raiseError: Function
    onSubmit: Function;
    onClear: Function,
    formFields: AssesementSearchFormType
}

function AssessmentSearchForm({ onSubmit, onClear, formFields, raiseError }: Props) {
    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isCountyDisabled, setCountyDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [openCountyDropDown, setOpenCountyDropDown] = useState(false);
    let countyRef: any;
    const stateFieldKeyPressed = useRef<string | null>(null);
    const dispatch = useAppDispatch();
    const countyListState = useAppSelector(CountyListData);
    const rerenderRef = useRef(0);

    const defaultValues: DefaultValues<AssesementSearchFormType> = {
        stateField: { id: "", label: "" },
        countyField: { county: "", fips: "" },
        LotFrom: "",
        LotTo: "",
        Block: "",
        District: "",
        Unit: "",
        SubDivision: "",
        PhaseNumber: "",
        TractNumber: "",
    };
    let messageRequired = "Required"

    const assessmentSearchValidationSchema = yup.object().shape({
        stateField: yup.object().shape({
            id: yup.string().required(messageRequired),
            label: yup.string().required(messageRequired),
        }),
        countyField: yup.object().shape({
            county: yup.string().required(messageRequired),
            fips: yup.string().required(messageRequired),
        }),
        LotFrom: yup.string().max(7, AssessmentSearchFormErrorMessages.LotFromLength)
            .test('GreaterValueError', AssessmentSearchFormErrorMessages.LotFromGreaterValue, function (value) {
                let lotToValue: string = this.resolve(yup.ref("LotTo"));
                let status = validateLotFromAndTo(specialCharacterFormatting(value), specialCharacterFormatting(lotToValue));
                if (status)
                    clearErrors("LotTo");
                return status;
            })
            .test('StartWithSpecialCharacterError', AssessmentSearchFormErrorMessages.StartWithSpecialCharacter_Lot, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        LotTo: yup.string().max(7, AssessmentSearchFormErrorMessages.LotToLength)
            .test('GreaterValueError', AssessmentSearchFormErrorMessages.LotFromGreaterValue, function (value) {
                let lotfromValue: string = this.resolve(yup.ref("LotFrom"));
                let status = validateLotFromAndTo(specialCharacterFormatting(lotfromValue), specialCharacterFormatting(value));
                if (status)
                    clearErrors("LotFrom");
                return status;
            })
            .test('StartWithSpecialCharacterError', AssessmentSearchFormErrorMessages.StartWithSpecialCharacter_Lot, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        Block: yup.string().max(7, AssessmentSearchFormErrorMessages.BlockLength),
        District: yup.string().max(12, AssessmentSearchFormErrorMessages.DistrictLength),
        Unit: yup.string().max(6, AssessmentSearchFormErrorMessages.UnitLength),
        SubDivision: yup.string().max(40, AssessmentSearchFormErrorMessages.subDivisionLength),
        PhaseNumber: yup.string().max(7, AssessmentSearchFormErrorMessages.PhaseNumberLength),
        TractNumber: yup.string().max(10, AssessmentSearchFormErrorMessages.TractNumberLength)
    });

    const form = useForm<AssesementSearchFormType>({
        defaultValues,
        resolver: yupResolver(assessmentSearchValidationSchema)
    });

    const { register, handleSubmit, setValue, resetField, reset, watch, clearErrors, getValues, trigger, setError, formState: { errors }, control } = form;

    const handleSubmitClick = (formData: AssesementSearchFormType) => {
        if (
            !formData.LotFrom?.trim() &&
            !formData.LotTo?.trim() &&
            !formData.Block?.trim() &&
            !formData.District?.trim() &&
            !formData.Unit?.trim() &&
            !formData.SubDivision?.trim() &&
            !formData.PhaseNumber?.trim() &&
            !formData.TractNumber?.trim()
        ) {
            setError("fieldValidationError", { type: "Empty_Form_Value", message: messageRequired });
            return;
        }

        if (formData.LotFrom && !formData.LotTo) {
            setValue("LotTo", formData.LotFrom);
        }

        if (formData.LotTo && !formData.LotFrom) {
            setValue("LotFrom", formData.LotTo);
        }

        formData.tabName = ASSESSMENT_SEARCH_FORM;
        onSubmit(formData);
    }

    const resetFormFields = () => {
        setValue("LotFrom", "");
        setValue("LotTo", "");
        setValue("Block", "");
        setValue("District", "");
        setValue("Unit", "");
        setValue("SubDivision", "");
        setValue("PhaseNumber", "");
        setValue("TractNumber", "");
    }

    const getCountyList = (value: any) => {
        if (value && value.id) {
            setCounty([]);
            resetField("countyField")
            const state = value.id.trim().toUpperCase();
            let param: stateParameter = {
                stateCode: state,
                pageName: ASSESSMENT_SEARCH_TAB
            }
            setSelectedState(state);
            dispatch(getCountyByStateCode(param))
            resetFormFields();
            clearErrorOnFields();
        }
    }

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    useEffect(() => {
        if (!formFields || formFields?.stateField?.id === "" || !formFields?.countyField?.fips) {
            setDisabled(true);
            setCountyDisabled(true);
        }
    }, []);

    useEffect(() => {
        if (countyListState.status === "loading")
            setLoading(true);
        else
            setLoading(false)

        if (countyListState && countyListState.county.match_code === "1"
            && (selectedState || formFields?.stateField?.id) && !countyListState.county.isExecuting) {
            setCounty(countyListState.county.countyList[ASSESSMENT_SEARCH_TAB]);
            if (formFields && formFields?.stateField?.id && formFields?.countyField?.fips) {
                setDisabled(false);
            }
            rerenderRef.current += 1;
            setCountyDisabled(false);
            setStateValue(getValues("stateField.id"));
        }
    }, [countyListState]);

    useEffect(() => {
        if (formFields
            && formFields?.stateField?.id !== ""
            && formFields?.countyField?.fips !== "") {

            setValue("stateField", formFields.stateField);
            setValue("countyField", formFields.countyField);
            setValue("LotFrom", formFields.LotFrom);
            setValue("LotTo", formFields.LotTo);
            setValue("Block", formFields.Block);
            setValue("District", formFields.District);
            setValue("Unit", formFields.Unit);
            setValue("SubDivision", formFields.SubDivision);
            setValue("PhaseNumber", formFields.PhaseNumber);
            setValue("TractNumber", formFields.TractNumber);
        }
    }, [formFields]);

    useEffect(() => {
        if (stateValue && !isCountyDisabled) {
            countyRef.focus();
            setOpenCountyDropDown(true);
        }
    }, [isCountyDisabled, rerenderRef.current]);

    const clearSearchResults = () => {
        reset(defaultValues);
        dispatch(resetAssessmentSearchState())
        setDisabled(true);
        setCountyDisabled(true);
        onClear();
    }

    useEffect(() => {

        if (Object.keys(errors).length > 1 && errors.fieldValidationError?.type === "Empty_Form_Value")
            clearErrors("fieldValidationError");

        raiseError(errors, form.getValues());
    }, [errors.stateField, errors.countyField, errors.LotFrom, errors.LotTo, errors.Block,
    errors.District, errors.Unit, errors.PhaseNumber, errors.TractNumber, errors.SubDivision, errors.fieldValidationError]);

    const handleCountyChange = () => {
        resetFormFields();
        setDisabled(false);
        lotFrom.focus();
        clearErrorOnFields();
    }

    const clearErrorOnFields = () => {
        clearErrors("fieldValidationError");
        clearErrors("stateField");
        clearErrors("countyField");
        clearErrors("LotFrom");
        clearErrors("LotTo");
        clearErrors("Block");
        clearErrors("District");
        clearErrors("Unit");
        clearErrors("SubDivision");
        clearErrors("PhaseNumber");
        clearErrors("TractNumber");
    }

    // const handleKeyUp = (event: any) => {
    //     const { key } = event;
    //     if (key === "Tab") {
    //         return;
    //     }

    //     const value = event.target.value.trim();
    //     let formData = form.getValues();
    //     if (value) {
    //         clearErrors("fieldValidationError");
    //     }
    //     else if (
    //         !formData.LotFrom?.trim() &&
    //         !formData.LotTo?.trim() &&
    //         !formData.Block?.trim() &&
    //         !formData.District?.trim() &&
    //         !formData.Unit?.trim() &&
    //         !formData.SubDivision?.trim() &&
    //         !formData.PhaseNumber?.trim() &&
    //         !formData.TractNumber?.trim()
    //     ) {
    //         setError("fieldValidationError", { type: "Empty_Form_Value", message: messageRequired });
    //     }
    // }

    let lotFrom: any;
    const lotFromWatch = watch('LotFrom');
    const [lotFromFocused, setLotFromFocused] = useState<boolean>(false);

    let lotTo: any;
    const lotToWatch = watch('LotTo');
    const [lotToFocused, setLotToFocused] = useState<boolean>(false);

    let block: any;
    const blockWatch = watch('Block');
    const [blockFocused, setblockFocused] = useState<boolean>(false);

    let district: any;
    const districtWatch = watch('District');
    const [districtFocused, setDistrictFocused] = useState<boolean>(false);

    let unit: any;
    const unitWatch = watch('Unit');
    const [unitFocused, setUnitFocused] = useState<boolean>(false);

    let subDivision: any;
    const subDivisionWatch = watch('SubDivision');
    const [subDivisionFocused, setSubDivisionFocused] = useState<boolean>(false);

    let phaseNumber: any;
    const phaseNumberWatch = watch('PhaseNumber');
    const [phaseNumberFocused, setphaseNumberFocused] = useState<boolean>(false);

    let tractNumber: any;
    const tractNumberWatch = watch('TractNumber');
    const [tractNumberFocused, setTractNumberFocused] = useState<boolean>(false);

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "LotFrom": {
                setLotFromFocused(true);
                break;
            }
            case "LotTo": {
                setLotToFocused(true);
                break;
            }
            case "Block": {
                setblockFocused(true);
                break;
            }
            case "District": {
                setDistrictFocused(true);
                break;
            }
            case "Unit": {
                setUnitFocused(true);
                break;
            }
            case "SubDivision": {
                setSubDivisionFocused(true);
                break;
            }
            case "PhaseNumber": {
                setphaseNumberFocused(true);
                break;
            }
            case "TractNumber": {
                setTractNumberFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "LotFrom": {
                // trigger('LotFrom');
                setLotFromFocused(false);
                break;
            }
            case "LotTo": {
                // trigger('LotTo');
                setLotToFocused(false);
                break;
            }
            case "Block": {
                // trigger('Block');
                setblockFocused(false);
                break;
            }
            case "District": {
                // trigger('District');
                setDistrictFocused(false);
                break;
            }
            case "Unit": {
                // trigger('Unit');
                setUnitFocused(false);
                break;
            }
            case "SubDivision": {
                // trigger('SubDivision');
                setSubDivisionFocused(false);
                break;
            }
            case "PhaseNumber": {
                // trigger('PhaseNumber');
                setphaseNumberFocused(false);
                break;
            }
            case "TractNumber": {
                // trigger('TractNumber');
                setTractNumberFocused(false);
                break;
            }
        }
    }

    const handleStateFieldKeyDown = (event: React.KeyboardEvent) => {
        stateFieldKeyPressed.current = event.key; // Store the key
    };

    const handleStateFieldBlur = (e: any) => {
        if (stateFieldKeyPressed.current === "Tab") {
            if (!e.target.value) {
                return;
            }
            const matchedState = stateListArray.find((state) => state.label === e.target.value.toUpperCase());
            if (matchedState) {
                setValue("stateField", matchedState);
                clearErrors("stateField");
                getCountyList(matchedState);
            }
        }
        stateFieldKeyPressed.current = null; // Reset the ref
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box>
                <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
                    <Grid container>
                        <Grid item xs={1}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <Controller
                                    name="stateField"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            {...register("stateField")}
                                            options={stateListArray}
                                            disableClearable
                                            getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                            noOptionsText={"No state"}
                                            renderInput={(params: any) => (
                                                <StyledTextField
                                                    {...params}
                                                    error={!!fieldState.error}
                                                    onBlur={handleStateFieldBlur}
                                                    onKeyDown={handleStateFieldKeyDown}
                                                    autoFocus={true}
                                                    label="State"
                                                    required
                                                    variant="outlined"
                                                    data-testid="StateField"
                                                />
                                            )}
                                            onChange={(_, data) => { field.onChange(data); getCountyList(data) }}
                                            data-testid="StateAutocomplete"
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.7}>
                            <GridItem>
                                <Controller
                                    name="countyField"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            disabled={isCountyDisabled}
                                            open={openCountyDropDown}
                                            onOpen={() => setOpenCountyDropDown(true)}
                                            onClose={() => setOpenCountyDropDown(false)}
                                            // openOnFocus
                                            {...register("countyField")}
                                            options={countyList}
                                            disableClearable
                                            noOptionsText={"No such county"}
                                            getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            onChange={(_, data) => { field.onChange(data); handleCountyChange() }}
                                            renderInput={(params: any) => (
                                                <StyledTextField
                                                    {...params}
                                                    inputRef={input => {
                                                        countyRef = input;
                                                    }}
                                                    error={!!fieldState.error}
                                                    label="County"
                                                    required
                                                    variant="outlined"
                                                    data-testid="CountyField"
                                                />
                                            )}
                                            data-testid="CountyAutocomplete"
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Typography sx={{ color: '#212121', fontSize: '14px', fontWeight: '600' }}>LOT</Typography>
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('LotFrom')}
                                    error={errors.LotFrom && errors.LotFrom.message !== "" ? true : false}
                                    id="LotFrom"
                                    inputRef={input => {
                                        lotFrom = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!lotFromWatch || lotFromFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    disabled={isDisabled}
                                    data-testid="LotFrom"
                                    fullWidth label="From" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <RemoveIcon sx={{ color: '#7F7F7F', fontSize: 18 }} />
                            </GridItem>
                        </Grid>
                        <Grid item xs={.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('LotTo')}
                                    error={errors.LotTo && errors.LotTo.message !== "" ? true : false}
                                    id="LotTo"
                                    inputRef={input => {
                                        lotTo = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!lotToWatch || lotToFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    disabled={isDisabled}
                                    data-testid="LotTo"
                                    fullWidth label="To" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('Block')}
                                    error={errors.Block && errors.Block.message !== "" ? true : false}
                                    id="Block"
                                    inputRef={input => {
                                        block = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!blockWatch || blockFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="Block"
                                    disabled={isDisabled}
                                    fullWidth label="Block" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.2}>
                            <GridItem>
                                <StyledTextField
                                    {...register('District')}
                                    error={errors.District && errors.District.message !== "" ? true : false}
                                    id="District"
                                    inputRef={input => {
                                        district = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!districtWatch || districtFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="District"
                                    fullWidth label="District" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('Unit')}
                                    error={errors.Unit && errors.Unit.message !== "" ? true : false}
                                    id="Unit"
                                    inputRef={input => {
                                        unit = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!unitWatch || unitFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="Unit"
                                    fullWidth label="Unit" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.7}>
                            <GridItem>
                                <StyledTextField
                                    {...register('SubDivision')}
                                    error={errors.SubDivision && errors.SubDivision.message !== "" ? true : false}
                                    id="SubDivision"
                                    inputRef={input => {
                                        subDivision = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!subDivisionWatch || subDivisionFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="SubDivision"
                                    fullWidth label="Subdivision" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.1}>
                            <GridItem>
                                <StyledTextField
                                    {...register('PhaseNumber')}
                                    error={errors.PhaseNumber && errors.PhaseNumber.message !== "" ? true : false}
                                    id="PhaseNumber"
                                    inputRef={input => {
                                        phaseNumber = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!phaseNumberWatch || phaseNumberFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="PhaseNumber"
                                    fullWidth label="Phase" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.2}>
                            <GridItem>
                                <StyledTextField
                                    {...register('TractNumber')}
                                    error={errors.TractNumber && errors.TractNumber.message !== "" ? true : false}
                                    id="TractNumber"
                                    inputRef={input => {
                                        tractNumber = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!tractNumberWatch || tractNumberFocused
                                    }}
                                    // onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    data-testid="TractNumber"
                                    fullWidth label="Tract" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                            <GridItem sx={{ textAlign: 'left' }}>
                                <GradientButton type="submit" size="large" data-testid="SearchButton" variant="contained" className={styles.buttonPadding} startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                    <Typography>Search</Typography>
                                </GradientButton>
                                <ClearButton type="reset" size="large" onClick={clearSearchResults} data-testid="ClearButton" className={styles.buttonPadding} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                    <Typography>Clear</Typography>
                                </ClearButton>
                            </GridItem>
                        </Grid>
                    </Grid>
                </form>
            </Box >
        </>
    )
}

export default AssessmentSearchForm