import { ParcelDetailsRequest, ParcelDetailsResponse } from '../../entities/ApiModel';

import { axiosSecuredInstance } from '../../configuration/axiosConfig';

export const fetchParcelDetails = async (parcelDetailsRequest: ParcelDetailsRequest): Promise<ParcelDetailsResponse> => {
    let propertyId = parcelDetailsRequest.propertyId;
    let fips = parcelDetailsRequest.fips;
    let subdivision_name = parcelDetailsRequest.subdivision_name;
    let longitude = parcelDetailsRequest.longitude;
    let latitude = parcelDetailsRequest.latitude;

    let parcelDetailsResponse: ParcelDetailsResponse = {
        propertyDetails: {},
        overviewDetails: {},
        salesDetails: {},
        physicalDetails: {},
        assessmentDetails: {},
        directStarter: {},
        referenceStarter: {},
        mapProperties: {}
    }
    if (!propertyId) {
        return parcelDetailsResponse;
    }
    try {
        const res = await Promise.all([
            axiosSecuredInstance.get("api/v1/property/" + propertyId, { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/direct-starters", { params: { fips: fips } }),
            axiosSecuredInstance.get("api/v1/property/" + propertyId + "/reference-starters", { params: { fips: fips, subdivision_name: subdivision_name, longitude: longitude, latitude: latitude } }),
        ]);
        parcelDetailsResponse.propertyDetails = res[0].data.data;
        parcelDetailsResponse.overviewDetails = res[0].data.data;
        parcelDetailsResponse.salesDetails = res[0].data.data;
        parcelDetailsResponse.physicalDetails = res[0].data.data;
        parcelDetailsResponse.assessmentDetails = res[0].data.data;
        parcelDetailsResponse.mapProperties = res[0].data.data;
        parcelDetailsResponse.directStarter = res[1].data.data;
        parcelDetailsResponse.referenceStarter = res[2].data.data; //removeDuplicates(res[5].data, res[6].data);
        return parcelDetailsResponse;
    } catch (e) {
        return parcelDetailsResponse;
    }
};

// function removeDuplicates(directStarterObj: any, referenceStarterObj: any): any {

//     if (directStarterObj == null || !Array.isArray(directStarterObj.direct_starter_details) || directStarterObj.direct_starter_details.length === 0) {
//         return referenceStarterObj;
//     }

//     const directStarterDetails = Array.isArray(directStarterObj?.direct_starter_details)
//         ? directStarterObj.direct_starter_details
//         : [];
//     const referenceStarterDetails = Array.isArray(referenceStarterObj?.reference_starter_details)
//         ? referenceStarterObj.reference_starter_details
//         : [];

//     const directPolicyNumbers = new Set(
//         directStarterDetails.map((detail: any) => detail?.policy_info?.policy_no ?? "")
//     );

//     const filteredReferenceStarter = referenceStarterDetails.filter(
//         (detail: any) => !directPolicyNumbers.has(detail?.policy_info?.policy_no ?? "")
//     );

//     return {
//         ...referenceStarterObj,
//         reference_starter_details: filteredReferenceStarter
//     };
// }