import React, { useEffect } from 'react'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import { DownloadImageButton, PropertyHeader } from '../../../components/StyledComponents/ParcelDetailStyles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FormatDateField, getLoggingEventName } from '../../../utils/common/commonUtils';
import * as UIModel from "../../../entities/UIModel";
import { Direct_Starter_Type_Log, DocumentView_Log_Event, LoggingEvent, Reference_Starter_Type_Log, search_status_subdivision, Starter__Document_Type_Log } from '../../../utils/constants/constants';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDocument, policyDoc, resetDocumentState } from '../../../common/Slice/policyDocumentSlice';
import LoadingProgressModel from '../../../components/ui/circularProgress/LoadingProgress';
import logAPI from '../../../common/Api/logAPI';
import styled from '@emotion/styled';
import styles from './policySearchResult.module.css';
import DirectStarterHeader from '../../parcelDetails/Headers/DirectStarterHeader';
import ReferenceStarterHeader from '../../parcelDetails/Headers/ReferenceStarterHeader';
import { PolicyHeader } from '../../../components/StyledComponents/CommonControls';


interface Props {
    policyInfo: any,
    handlePropertyDetailsClick: Function,
    isStarter?: Boolean,
    isDirectStarter?: Boolean,
    isReferenceStarter?: Boolean,
    tabName: string,
    isPropertyDetailsOpen: boolean,
}

// export const PolicyHeader = styled(Typography)`
//     display: flex;
//     align-items: center;
//     padding: 10px 0px;
//     font-weight: 700;
//     color:#004d82;
//     cursor: pointer;
// `;

const HeaderResult = ({ policyInfo, handlePropertyDetailsClick, tabName, isStarter, isDirectStarter, isReferenceStarter,
    isPropertyDetailsOpen }: Props) => {
    // const [propertyDetailsOpen, setPropertyDetails] = React.useState(false);
    const [documentString, setDocumentString] = React.useState("");
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [randomUUId, setRandomUUId] = React.useState("");

    const dispatch = useAppDispatch();
    const policyDocumentList = useAppSelector((state: any) => state.rootReducer.policyDocument);//.documentList[policyInfo?.doc_image_name + policyInfo?.policy_no + policyInfo?.row_number]);
    const policyDocState = useAppSelector(policyDoc);
    let policyNumber = policyInfo?.policy_no;

    let pdfDocument: UIModel.PdfDocument = {
        base64String: documentString,
        fileName: "Policy Document",
        dDocName: ""
    }

    useEffect(() => {
        //dispatch(resetDocumentState());
        // setRandomUUId(GetUUId());
    }, []);

    useEffect(() => {
        if (policyDocumentList) {
            const doc: any = policyDocumentList.documentList;
            if (doc?.isRequestCompleted && (!doc?.documentData)) {
                setIsLoading(false);
                // switch (doc?.matchCode) {
                //     case "-2": {
                //         setWarningNotification(true);
                //         break;
                //     }
                //     default: {
                //         setErrorNotification(true);
                //         break;
                //     }
                // }
                // return;
            }
            if (doc?.documentData) {
                setIsLoading(false);
                // setCount(count + 1);
                // setDocumentString(doc?.documentData?.data)
                // openDocument();
            }
        }
    }, [policyDocumentList]);

    // const openDocument = () => {
    //     setOpenPdfViewer(true);
    //     setDocumentLoaded(true);
    // }

    // useEffect(() => {
    //     if (policyDocState?.apiStatus === "fulfilled") {
    //         setIsLoading(false);
    //     }
    // }, [policyDocState]);

    useEffect(() => {
        if (policyDocState.status === "failed") {
            setIsLoading(false);
        }
    }, [policyDocState]);

    const fetchDocument = () => {
        // if (isDocumentLoaded) {
        //     setRandomUUId(GetUUId());
        //     return;
        // }
        // else {
        dispatch(resetDocumentState());
        if (policyInfo) {
            logAPI({
                Event: DocumentView_Log_Event,
                FileNumber: policyInfo?.file_number,
                PolicyNumber: policyInfo?.policy_no,
                DocumentName: policyInfo?.doc_image_name,
                // FullStreetAddress: directStarter?.property_full_street_address,
                // city: directStarter?.city,
                // state: directStarter?.state,
                // zip: directStarter?.zip,
                // UnitNumber: "",
                // APN: directStarter?.apn,
                // Fips: directStarter?.fips,
                // PolicyNumber: directStarter?.full_policy_number,
                StarterType: isDirectStarter ? Direct_Starter_Type_Log : isReferenceStarter ? Reference_Starter_Type_Log : ""
            });
            setIsLoading(true);
            // setRandomUUId(GetUUId());
            dispatch(getDocument({
                fullPolicyNumber: policyInfo?.policy_no,
                docName: policyInfo?.doc_image_name, rowNo: policyInfo?.row_number, fileNumber: policyInfo?.file_number,
                documentSource: policyInfo?.document_source, docImageId: policyInfo?.doc_image_id
            }));
        }
        // }
    }

    const onClose = () => {
        dispatch(resetDocumentState())
        setOpenPdfViewer(false);
        setDocumentLoaded(false);
    }

    const handlePropertyDetailsOpen = (row_number: number) => {
        // setPropertyDetails(!propertyDetailsOpen)
        handlePropertyDetailsClick(row_number);
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box className={styles.documentImageAlignment}>
                <Box className={styles.claimAlertAlignment}>
                    {
                        !isStarter &&
                        <PolicyHeader onClick={() => handlePropertyDetailsOpen(policyInfo?.row_number)} variant="h6">
                            {isPropertyDetailsOpen ? <KeyboardArrowUpIcon data-testid={policyNumber + "PropertyDetailClose"} />
                                : <KeyboardArrowDownIcon data-testid={policyNumber + "PropertyDetailOpen"} />} &nbsp;&nbsp;Policy
                        </PolicyHeader>
                    }
                    {
                        isStarter &&
                        <>

                            {
                                isDirectStarter && <DirectStarterHeader policyNumber={policyInfo?.policy_no}
                                    handleDirectStarterSectionClick={handlePropertyDetailsClick}
                                    rowNumber={policyInfo?.row_number}
                                    claimNo={policyInfo?.claim_no} />
                            }
                            {
                                isReferenceStarter && <ReferenceStarterHeader policyNumber={policyInfo?.policy_no}
                                    handleDirectStarterSectionClick={handlePropertyDetailsClick}
                                    rowNumber={policyInfo?.row_number}
                                    claimNo={policyInfo?.claim_no}
                                    isSubDivision={policyInfo?.search_status === search_status_subdivision ? true : false}
                                    searchProximity={policyInfo?.search_proximity ?? 0.00}
                                    subDivisionName={policyInfo?.subdivision_name} />
                            }
                        </>
                    }
                    {
                        policyInfo?.claim_no &&
                        // <Box className={styles.claimAlertAlignment}>
                        <Typography data-testid={policyNumber + "_ClaimNo"} sx={{ color: '#E00000', paddingLeft: '20px', fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {policyInfo?.claim_no}</Typography>
                        // </Box>
                    }
                </Box>
                <Box>
                    {
                        !policyInfo?.claim_no &&
                        policyInfo?.doc_image_id &&
                        <Grid item xs={3.3} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <DownloadImageButton onClick={fetchDocument} data-testid={policyNumber + "_DocumentImage"} sx={{ backgroundColor: "white", maxHeight: "36px" }} variant="outlined" startIcon={<UploadFileIcon />}>
                                Document Image
                            </DownloadImageButton>
                        </Grid>
                    }
                </Box>
            </Box>
            <ul className={`${styles.parcelList} ${styles.headerDetailsPadding}`}>
                <li className={styles.parcelListData}>
                    <Typography className={styles.headerQuestion}>
                        Policy Effective Date
                    </Typography>
                    <Typography data-testid={policyNumber + "_PolicyEffectiveDate"} className={styles.headerAnswer}>
                        {FormatDateField(policyInfo?.policy_effective_date)}
                    </Typography>
                </li>
                <li className={styles.parcelListData}>
                    <Typography className={styles.headerQuestion}>
                        File Number
                    </Typography>
                    <Typography data-testid={policyNumber + "_FileNumber"} className={styles.headerAnswer}>
                        {policyInfo?.file_number}
                    </Typography>
                </li>
                <li className={styles.parcelListData}>
                    <Typography className={styles.headerQuestion}>
                        Full Policy Number
                    </Typography>
                    <Typography data-testid={policyNumber + "_FullPolicyNumber"} className={styles.headerAnswer}>
                        {policyInfo?.policy_no}
                    </Typography>
                </li>
                <li className={styles.parcelListData}>
                    <Typography className={styles.headerQuestion}>
                        Transaction Type
                    </Typography>
                    <Typography data-testid={policyNumber + "_TransactionType"} className={styles.headerAnswer}>
                        {policyInfo?.transaction_type}
                    </Typography>
                </li>
            </ul>
            <ul className={`${styles.parcelList} ${styles.headerDetailsPadding}`}>
                <li className={styles.parcelListDataTwoSection}>
                    <Typography className={styles.headerQuestion}>
                        Grantor
                    </Typography>
                    <Typography data-testid={policyNumber + "_Grantor"} className={styles.headerAnswer}>
                        {policyInfo?.grantor}
                    </Typography>
                </li>
                <li className={styles.parcelListDataTwoSection}>
                    <Typography className={styles.headerQuestion}>
                        Grantee
                    </Typography>
                    <Typography data-testid={policyNumber + "_Grantee"} className={styles.headerAnswer}>
                        {policyInfo?.grantee}
                    </Typography>
                </li>
            </ul>
            <Collapse in={isPropertyDetailsOpen} timeout="auto" unmountOnExit>
                <ul className={`${styles.parcelList} ${styles.headerDetailsPadding}`}>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Insured Amount
                        </Typography>
                        <Typography data-testid={policyNumber + "_InsuredAmount"} className={styles.headerAnswer}>
                            {policyInfo?.insured_amount ? "$" : ""}{policyInfo?.insured_amount ? Number(policyInfo?.insured_amount).toLocaleString() : ""}
                        </Typography>
                    </li>
                    <li className={styles.parcelListData}>
                        <Typography className={styles.headerQuestion}>
                            Policy Source
                        </Typography>
                        <Typography data-testid={policyNumber + "_PolicySource"} className={styles.headerAnswer}>
                            {policyInfo?.policy_source}
                        </Typography>
                    </li>
                </ul>
                <ul className={`${styles.parcelList} ${styles.headerDetailsPadding}`}>
                    <li className={styles.parcelListDataOneSection}>
                        <Typography className={styles.headerQuestion}>
                            Insured Party
                        </Typography>
                        <Typography data-testid={policyNumber + "_InsuredParty"} className={styles.headerAnswer}>
                            {policyInfo?.insured_party}
                        </Typography>
                    </li>
                </ul>
            </Collapse>
            {/* <Grid container rowSpacing={1.5}>

                <Grid item xs={1.5} md={2} lg={2} xl={1.5}>
                    <PropertyHeader>Policy Effective Date</PropertyHeader>
                    <Typography data-testid={policyNumber + "_PolicyEffectiveDate"}>{FormatDateField(policyInfo?.policy_effective_date)}</Typography>
                </Grid>
                <Grid item xs={1} md={1.7} lg={1.5} xl={1}>
                    <PropertyHeader>File Number</PropertyHeader>
                    <Typography data-testid={policyNumber + "_FileNumber"}>{policyInfo?.file_number}</Typography>
                </Grid>
                
                <Grid item xs={1.3} md={2} lg={2} xl={1.3}>
                    <PropertyHeader>Full Policy Number</PropertyHeader>
                    <Typography data-testid={policyNumber + "_FullPolicyNumber"}>{policyInfo?.policy_no}</Typography>
                </Grid>
               
                <Grid item xs={1.3} md={1.5} lg={1.5} xl={1.3}>
                    <PropertyHeader>Transaction Type</PropertyHeader>
                    <Typography data-testid={policyNumber + "_TransactionType"}>{policyInfo?.transaction_type}</Typography>
                </Grid>
                
                <Grid item xs={1} md={1.7} lg={2} xl={1}>
                    <PropertyHeader>Insured Party</PropertyHeader>
                    <Typography data-testid={policyNumber + "_InsuredParty"}>{policyInfo?.insured_party}</Typography>
                </Grid>

                <Grid item xs={1.2} md={2} lg={1.5} xl={1.2}>
                    <PropertyHeader>Insured Amount</PropertyHeader>
                    <Typography data-testid={policyNumber + "_InsuredAmount"} sx={{ wordBreak: 'break-word' }}>{policyInfo?.insured_amount ? "$" : ""}{policyInfo?.insured_amount ? Number(policyInfo?.insured_amount).toLocaleString() : ""}</Typography>
                </Grid>

                <Grid item xs={1} md={2} lg={1.3} xl={1.3}>
                    <PropertyHeader>Policy Source</PropertyHeader>
                    <Typography data-testid={policyNumber + "_PolicySource"}>{policyInfo?.policy_source}</Typography>
                </Grid>

                {
                    policyInfo?.claim_no && !isStarter &&
                    <Grid item xs={2.7} md={6} lg={6.2} xl={3.2}>
                        <Typography data-testid={policyNumber + "_ClaimNo"} sx={{ color: '#E00000', textAlign: "center", fontSize: '20px', fontStyle: 'normal', fontWeight: 500, letterSpacing: '0.15px' }}>CLAIM ALERT! - Claim No.: {policyInfo?.claim_no}</Typography>
                    </Grid>
                }
                
            </Grid> */}
        </>
    )
}

export default HeaderResult